/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-console */
import { isAxiosError } from 'axios'

export interface Logger {
  debug: LogFunction
  info: LogFunction
  warn: LogFunction
  error: LogFunction
  timerStart: TimerFunction
  timerElapsed: TimerFunction
  timerEnd: TimerFunction
}

export interface SendLogMessagePayload<T> {
  getDefaultContext: DefaultContextFn
  logger: T
  level: LOG_LEVEL_TYPE
  message: string
  context?: any
  error?: unknown
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LogFunction = (message: string, context?: any, error?: unknown) => void

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TimerFunction = (timerName: string, context?: any, error?: unknown) => void

const SUPPPORTED_LOG_LEVELS = ['debug', 'info', 'warn', 'error'] as const
export type LOG_LEVEL_TYPE = typeof SUPPPORTED_LOG_LEVELS[number]

export type DefaultContextFn = () => Record<string, any>

export function isClient() {
  return typeof window !== 'undefined'
}

export function isAnError(maybeError: any): maybeError is Error {
  return (
    isAxiosError(maybeError) ||
    maybeError instanceof Error ||
    (maybeError && !!maybeError.stack && !!maybeError.message)
  )
}

export function getErrorFromContext(context: any): Error | null {
  if (!context) return null

  if (isAnError(context)) return context
  if (isAnError(context.error)) return context.error
  if (isAnError(context.err)) return context.err
  if (isAnError(context.e)) return context.e

  return null
}

const logLevelMap = {
  debug: 'debug',
  info: 'info',
  warn: 'warn',
  error: 'error',
} as const

export function getConfiguredLogLevel(): LOG_LEVEL_TYPE {
  const logLevel = (process.env.NEXT_PUBLIC_LOG_LEVEL || 'info').toLowerCase() as LOG_LEVEL_TYPE
  return logLevelMap[logLevel] ?? 'info'
}

const logLevelNumberMap = { debug: 0, info: 1, warn: 2, error: 3 }

export function getLogLevelNumber(logLevel: LOG_LEVEL_TYPE): number {
  return logLevelNumberMap[logLevel] ?? 1
}

export function getNoopLogger(): Logger {
  return {
    debug() {},
    info() {},
    warn() {},
    error() {},
    timerStart() {},
    timerElapsed() {},
    timerEnd() {},
  }
}

interface CreateLoggerParams<T> {
  logger: T
  getDefaultContext: DefaultContextFn
  sendLogMessage: (payload: SendLogMessagePayload<T>) => void
}

export function createLogger<T>({ logger, getDefaultContext, sendLogMessage }: CreateLoggerParams<T>): Logger {
  const timers: Record<string, number> = {}

  return {
    debug(message, context, error) {
      sendLogMessage({ level: 'debug', message, context, error, getDefaultContext, logger })
    },
    info(message, context, error) {
      sendLogMessage({ level: 'info', message, context, error, getDefaultContext, logger })
    },
    warn(message, context, error) {
      sendLogMessage({ level: 'warn', message, context, error, getDefaultContext, logger })
    },
    error(message, context, error) {
      sendLogMessage({ level: 'error', message, context, error, getDefaultContext, logger })
    },
    timerStart(timerName, context, error) {
      const d = new Date()
      timers[timerName] = d.getTime()
      const message = `Timer "${timerName}" started at: ${d.toISOString()}`
      this.info(message, context, error)
    },
    timerElapsed(timerName, context, error) {
      const startTime = timers[timerName]

      if (!startTime) {
        const message = `Timer "${timerName}" not found. This timer either was never started or has already been ended.`
        return this.info(message, context, error)
      }

      const d = new Date()
      const elapsedMs = d.getTime() - startTime
      const message = `Timer "${timerName}" elapsed: ${elapsedMs}ms`

      this.info(message, { elapsedMs, ...context }, error)
    },
    timerEnd(timerName, context, error) {
      const startTime = timers[timerName]

      if (!startTime) {
        const message = `Timer "${timerName}" not found. This timer either was never started or has already been ended.`
        return this.info(message, context, error)
      }

      const d = new Date()
      const elapsedMs = d.getTime() - startTime
      const message = `Timer "${timerName}" ended at: ${d.toISOString()} with a total time of: ${elapsedMs}ms`
      delete timers[timerName]

      this.info(message, { elapsedMs, ...context }, error)
    },
  }
}
