export enum Durations {
  ONE_SECOND_IN_MILLISECONDS = 1000,
  ONE_MINUTE_IN_SECONDS = 60,
  ONE_MINUTE_IN_MILLISECONDS = 60 * 1000,
  TWO_MINUTES_IN_SECONDS = 2 * 60,
  FIVE_MINUTES_IN_SECONDS = 5 * 60,
  TEN_MINUTES_IN_SECONDS = 10 * 60,
  FIFTEEN_MINUTES_IN_SECONDS = 15 * 60,
  TWENTY_MINUTES_IN_SECONDS = 20 * 60,
  THIRTY_MINUTES_IN_SECONDS = 30 * 60,
  ONE_HOUR_IN_SECONDS = 60 * 60,
  ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000,
  ONE_DAY_IN_SECONDS = 24 * 60 * 60,
  ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000,
  ONE_WEEK_IN_SECONDS = 7 * 24 * 60 * 60,
  ONE_WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000,
  ONE_MONTH_IN_SECONDS = 30 * 24 * 60 * 60,
  ONE_MONTH_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000,
  ONE_YEAR_IN_SECONDS = Math.floor(365.25 * 24 * 60 * 60),
  TEN_YEARS_IN_SECONDS = Math.floor(10 * 365.25 * 24 * 60 * 60),
}
