import { z } from 'zod'
// import { SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS } from '../../constants'
import {
  defaultFacebookPixelSettings,
  FacebookPixelSettings,
} from '../segment-plugin/plugins/custom-facebook-pixel-plugin'

export const CustomFacebookPixels: Record<string, FacebookPixelSettings> = {
  'Streaming Facebook Pixel': {
    ...defaultFacebookPixelSettings,
    pixelId: '915563282734100',
    standardEvents: [
      {
        event: 'Order Completed',
        standardEventName: 'Order Completed',
      },
      {
        event: 'Checkout Started',
        standardEventName: 'Checkout Started',
      },
      {
        event: 'Product Added',
        standardEventName: 'Product Added',
      },
      {
        event: 'Product Viewed',
        standardEventName: 'Product Viewed',
      },
      {
        event: 'Order Completed - streaming',
        standardEventName: 'Order Completed',
        schema: z.object({
          funnel: z.literal('streaming'),
        }),
      },
      {
        event: 'Checkout Started - streaming',
        standardEventName: 'Checkout Started',
        schema: z.object({
          funnel: z.literal('streaming'),
        }),
      },
      {
        event: 'Product Added - streaming',
        standardEventName: 'Product Added',
        schema: z.object({
          funnel: z.literal('streaming'),
        }),
      },
      {
        event: 'Product Viewed - streaming',
        standardEventName: 'Product Viewed',
        schema: z.object({
          funnel: z.literal('streaming'),
        }),
      },
      {
        event: 'Order Completed - pif',
        standardEventName: 'Order Completed',
        schema: z.object({
          funnel: z.literal('pif'),
        }),
      },
      {
        event: 'Checkout Started - pif',
        standardEventName: 'Checkout Started',
        schema: z.object({
          funnel: z.literal('pif'),
        }),
      },
      {
        event: 'Product Added - pif',
        standardEventName: 'Product Added',
        schema: z.object({
          funnel: z.literal('pif'),
        }),
      },
      {
        event: 'Product Viewed - pif',
        standardEventName: 'Product Viewed',
        schema: z.object({
          funnel: z.literal('pif'),
        }),
      },
      {
        event: 'TVOD Add To Cart',
        standardEventName: 'Product Added',
      },
    ],
    allowEcommerceSpecEvents: false,
  },
  // 'Theatrical Facebook Pixel': {
  //   ...defaultFacebookPixelSettings,
  //   pixelId: '723986607933768',
  //   standardEvents: [
  //     {
  //       event: 'Order Completed - theatrical',
  //       standardEventName: 'Order Completed',
  //       schema: z.object({
  //         funnel: z.literal('theatrical'),
  //         projectSlug: z.string().refine((slug) => !SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS.includes(slug)),
  //       }),
  //     },
  //     {
  //       event: 'Checkout Started - theatrical',
  //       standardEventName: 'Checkout Started',
  //       schema: z.object({
  //         funnel: z.literal('theatrical'),
  //         projectSlug: z.string().refine((slug) => !SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS.includes(slug)),
  //       }),
  //     },
  //     {
  //       event: 'Product Added - theatrical',
  //       standardEventName: 'Product Added',
  //       schema: z.object({
  //         funnel: z.literal('theatrical'),
  //         projectSlug: z.string().refine((slug) => !SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS.includes(slug)),
  //       }),
  //     },
  //     {
  //       event: 'Product Viewed - theatrical',
  //       standardEventName: 'Product Viewed',
  //       schema: z.object({
  //         funnel: z.literal('theatrical'),
  //         projectSlug: z.string().refine((slug) => !SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS.includes(slug)),
  //       }),
  //     },
  //   ],
  //   onlyTrackLandingPage: true,
  //   pageViewFilter: (track) => {
  //     const url = track.proxy('properties.url') as string | undefined
  //     return !!url && /tickets|movies/.test(url)
  //   },
  // },
  'Sweepstakes Facebook Pixel': {
    ...defaultFacebookPixelSettings,
    pixelId: '569046179454832',
    standardEvents: [
      {
        event: 'Product Added - theatrical-pre-sale',
        standardEventName: 'Product Added',
        schema: z.object({
          funnel: z.literal('theatrical-pre-sale'),
        }),
      },
      {
        event: 'Product Viewed - theatrical-pre-sale',
        standardEventName: 'Product Viewed',
        schema: z.object({
          funnel: z.literal('theatrical-pre-sale'),
        }),
      },
    ],
    onlyTrackLandingPage: true,
    pageViewFilter: (track) => {
      const url = track.proxy('properties.url') as string | undefined
      return !!url && /pre-sales/.test(url)
    },
  },
  'Rule Breakers Facebook Pixel': {
    ...defaultFacebookPixelSettings,
    pixelId: '620425533876247',
    standardEvents: [
      {
        event: 'Order Completed - theatrical',
        standardEventName: 'Order Completed',
        schema: z.object({
          funnel: z.literal('theatrical'),
          projectSlug: z.literal('rule-breakers'),
        }),
      },
      {
        event: 'Checkout Started - theatrical',
        standardEventName: 'Checkout Started',
        schema: z.object({
          funnel: z.literal('theatrical'),
          projectSlug: z.literal('rule-breakers'),
        }),
      },
      {
        event: 'Product Added - theatrical',
        standardEventName: 'Product Added',
        schema: z.object({
          funnel: z.literal('theatrical'),
          projectSlug: z.literal('rule-breakers'),
        }),
      },
      {
        event: 'Product Viewed - theatrical',
        standardEventName: 'Product Viewed',
        schema: z.object({
          funnel: z.literal('theatrical'),
          projectSlug: z.literal('rule-breakers'),
        }),
      },
      {
        event: 'Product Added - theatrical-pre-sale',
        standardEventName: 'Product Added',
        schema: z.object({
          funnel: z.literal('theatrical-pre-sale'),
          projectSlug: z.literal('rule-breakers'),
        }),
      },
    ],
  },
}
